import { Box, Grid, GridItem, Heading, Tag, Text } from '@chakra-ui/react';
import Link from 'next/link';
import { AiFillStar } from "react-icons/ai";

function FeaturedJobs({ jobs }) {
    return (
        <>

            <Grid
                templateRows='repeat(1, 1fr)'
                templateColumns={['repeat(1, 1fr)', 'repeat(3, 1fr)']}
                gap={[8, 6]}
            >
                {jobs?.map((job, index) => {
                    if (job.type === 'FEATURED') {
                        return (
                            <GridItem key={index}>
                                <Link href={`/job/${job.position_slug}/${job.job_id}`}>
                                    <Box position={'relative'} borderWidth={job.type === 'FEATURED' ? "2px" : "1px"} borderColor={job.type === 'FEATURED' ? "gray.100" : "gray.400"} bgColor={job.type === 'FEATURED' ? "#1B1B1B" : "#1B1B1B"} borderRadius="lg" p={4} minH={'114px'}>
                                        <Heading as={'h4'} fontSize={'xl'} mr={'15px'}>{job.position}</Heading>
                                        <Text fontSize={'xl'}>{job.company_name}</Text>

                                        {job?.employment_type && <Tag mt={2}>{job.employment_type}</Tag>}
                                        {job?.experience && <Tag ml={2} mt={2}>{job.experience}</Tag>}
                                        {job?.region && <Tag ml={2} mt={2}>{job.region}</Tag>}

                                        <Box position={'absolute'} top={3} right={3}>
                                            <AiFillStar size={'24px'} color={'orange'} />
                                        </Box>
                                    </Box>
                                </Link>
                            </GridItem>
                        )
                    }
                }
                )}
            </Grid>
        </>
    )
}

export default FeaturedJobs;