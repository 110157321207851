import { Box, Button, Container, Heading, Text } from "@chakra-ui/react";
import { format, subDays } from "date-fns";
import Link from "next/link";
import CategoriesList from "../components/CategoriesList";
import CountriesList from "../components/CountriesList";
import FeaturedJobs from "../components/FeaturedJobs";
import Footer from "../components/Footer";
import HomeHero from "../components/HomeHero";
import JobsAsGrid from "../components/JobsAsGrid";
import MetaSeo from "../components/MetaSeo";
import PopularQueries from "../components/PopularQueries";
import { supabase } from '../utils/supabaseClient';

const newIndex = ({ jobs, proletariatJobs, psyonixJobs }) => {
    return (
        <>
            <MetaSeo description="Browse thousands of game dev jobs. Find your next gig." keywords="games jobs, gaming jobs, game dev jobs, game developer jobs, jobs">
                <title>Game dev jobs - Home of game developer jobs</title>
            </MetaSeo>

            <HomeHero />

            {/* <HomeAI /> */}

            <Container minW={'full'} maxW={'full'} bgColor={'#151515'} pt={'3'} pos={'relative'} zIndex={'20'}>
                <Container maxW={'7xl'} pt={8}>

                    <Heading as={'h2'} fontSize={'3xl'} py={6}>Featured game dev jobs</Heading>
                    <FeaturedJobs jobs={jobs} />
                </Container>
            </Container>

            <Container minW={'full'} maxW={'full'} bgColor={'#151515'} pt={'3'} pos={'relative'} zIndex={'20'}>
                <Container maxW={'7xl'} pt={8}>
                    <Box borderWidth={'2px'} borderRadius={'lg'} borderColor={'#fff'} bgColor={'seashell'} p={6}>
                        <Text textAlign={'center'} fontSize={"xl"} color={'black'}>
                            If you&apos;re tired of sifting through endless job postings, try our AI-powered job search tool!
                        </Text>
                        <Text textAlign={'center'} fontSize={"xl"} fontWeight={'bold'} color={'black'}>
                            It&apos;s a game-changer.
                        </Text>
                        <Box textAlign={'center'} mt={8}>
                            <Link href={'https://gameindustryjobs.io/sign-up'}>
                                <Button borderRadius={'36px'} bgColor={'#2B2BFC'} _hover={{ backgroundColor: '#1f1fb1' }} _active={{ color: '#fff' }} color={'#fff'} textTransform={'uppercase'} px={'12'} py={'6'}>
                                    Sign up
                                </Button>
                            </Link>
                        </Box>
                    </Box>
                </Container>
            </Container>

            <Container minW={'full'} maxW={'full'} bgColor={'#151515'} pt={'3'} pos={'relative'} zIndex={'20'}>
                <Container maxW={'7xl'} py={8}>
                    <Heading as={'h3'} fontSize={'2xl'} py={6}>Latest game dev jobs</Heading>
                    <JobsAsGrid jobs={jobs} />
                </Container>
            </Container>

            <CategoriesList />

            {/* <JobByCompany companyName={'Proletariat'} jobs={proletariatJobs} /> */}
            <PopularQueries />
            {/* <JobByCompany companyName={'Psyonix'} jobs={psyonixJobs} /> */}
            <CountriesList />

            <Footer />
        </>
    )
}

export default newIndex;



export async function getStaticProps() {
    const _45DaysAgo = subDays(new Date(), 45)

    const { data: jobs, count } = await
        supabase.from("job")
            .select("job_id, company_name, company_slug, position, position_slug, type, created", { count: "exact" })
            .gte('updated_at', format(_45DaysAgo, "yyyy-MM-dd HH:mm:ss"))
            .order("type", { ascending: true })
            .order("created", { ascending: false })
            .limit(18)

    const { data: proletariatJobs } = await
        supabase.from("job")
            .select("job_id, company_name, company_slug, position, position_slug, type, created")
            .eq("company_id", 81)
            .gte('updated_at', format(_45DaysAgo, "yyyy-MM-dd HH:mm:ss"))
            .order("type", { ascending: true })
            .order("created", { ascending: false })
            .limit(12)

    const { data: psyonixJobs } = await
        supabase.from("job")
            .select("job_id, company_name, company_slug, position, position_slug, type, created")
            .eq("company_id", 41)
            .gte('updated_at', format(_45DaysAgo, "yyyy-MM-dd HH:mm:ss"))
            .order("type", { ascending: true })
            .order("created", { ascending: false })
            .limit(12)

    const pages = (count / 10);

    return {
        props: { jobs, proletariatJobs, psyonixJobs, pages },
        revalidate: 36000
    }
}

