import { Box, Button, ButtonGroup, Container, Flex, Heading, Input, InputGroup, InputLeftElement, Link, Show, Stack, Text } from '@chakra-ui/react';
import Image from 'next/image';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { BiSearch } from 'react-icons/bi';
import slugify from '../helpers/slugify';
import MobileMenu from './Menu';
const Logo = '/gamedevjobs-logo-1.svg';

const HomeHero = () => {
    const router = useRouter();
    const [keyword, setKeyword] = useState('');

    const search = async (e) => {
        if (e.target.value.length && e.key === 'Enter') {
            router.push(`/search?q=${slugify(keyword)}`)
        }
    }

    return (
        <Container maxW={'full'} px={0} h="auto" backgroundImage={'url("/img/home-bg.jpg")'} bgRepeat={'no-repeat'} bgSize={'cover'}>
            <Container maxW={'full'} height={'436px'} maxH={'100%'} pos={'absolute'} bgColor={'#181818'} opacity={'0.67'} zIndex={'1'} />
            <Container maxW="7xl" py="4" px={[4, 4, 4, 4, 0]} zIndex={'10'} pos={'relative'}>
                <Flex flexDirection={'row'} alignItems={'center'} justifyContent={'center'}>
                    <Box w={'80px'}>
                        <NextLink href='/' passHref legacyBehavior>
                            <Link mr={'2rem'} minW={'120px'} title='GameDevJobs'>
                                <Image src={Logo} width='76' height='63' color='#000' alt='gamedevjobs logo' />
                            </Link>
                        </NextLink>
                    </Box>
                    <Box mr={'auto'} textAlign={'left'} pl={'8'}>
                        <NextLink href='/search' passHref color={'#fff'} legacyBehavior>
                            <Link fontWeight={'bold'} color={'#fff'} title='GameDevJobs' textTransform={'uppercase'}>
                                Explore Jobs
                            </Link>
                        </NextLink>
                    </Box>

                    <Show below='md'>
                        <MobileMenu />
                    </Show>

                    <Show above='md'>
                        <ButtonGroup>
                            <NextLink href='https://gameindustryjobs.io/sign-up' passHref legacyBehavior>
                                <Link isExternal>
                                    <Button borderRadius={'36px'} bgColor={'#181818'} _hover={{ backgroundColor: '#000' }} _active={{ color: '#fff' }} color={'#fff'} textTransform={'uppercase'} px={'12'} py={'6'}>
                                        Create Profile
                                    </Button>
                                </Link>
                            </NextLink>
                            <NextLink href='/post-job' passHref legacyBehavior>
                                <Link>
                                    <Button borderRadius={'36px'} bgColor={'#2B2BFC'} _hover={{ backgroundColor: '#1f1fb1' }} _active={{ color: '#fff' }} color={'#fff'} textTransform={'uppercase'} px={'12'} py={'6'}>
                                        Post a Job
                                    </Button>
                                </Link>
                            </NextLink>
                        </ButtonGroup>
                    </Show>
                </Flex>
                <Box py={['8', '12']} my={['4', '8']} w={"full"} textAlign={'center'} color={'#fff'} pos={'relative'} zIndex={'20'}>
                    <Heading as={'h1'} fontSize={['2xl', '40px']} letterSpacing={'1px'} fontWeight={700}>
                        Game Dev Jobs
                    </Heading>
                    <Text fontSize={['sm', 'md', '24px']} letterSpacing={'1px'} fontWeight={500} my={'4'}>
                        Discover your next career move, freelance gig or internship
                    </Text>
                    <Stack spacing={4} display={'flex'} alignContent={'center'} justifyItems={'center'}>
                        <InputGroup maxW={'670px'} alignSelf={'center'}>
                            <InputLeftElement
                                pointerEvents='none'
                                pl={2}
                            >
                                <BiSearch size={'24px'} color={'#fff'} />
                            </InputLeftElement>
                            <Input type={'text'} fontWeight={'bold'} borderRadius={'36px'} placeholder={'Search jobs by keyword...'} bgColor={'#181818'} color={'gray.100'} value={keyword} onKeyDown={(e) => search(e)} onChange={(e) => setKeyword(e.target.value)} />
                        </InputGroup>
                    </Stack>
                </Box>
            </Container>
        </Container >
    );
}

export default HomeHero;
