import { Container, Heading, Link, SimpleGrid } from "@chakra-ui/react";
import NextLink from "next/link";
import slugify from "../helpers/slugify";

const countries = [
    'Australia',
    'Belgium',
    'Brazil',
    'Bulgaria',
    'Canada',
    'Colombia',
    'Czech Republic',
    'Denmark',
    'Finland',
    'France',
    'Germany',
    'India',
    'Ireland',
    'Italy',
    'Japan',
    'Malaysia',
    'Malta',
    'Netherlands',
    'Northern Ireland',
    'Philippines',
    'Poland',
    'Portugal',
    'South Korea',
    'Spain',
    'Sweden',
    'Thailand',
    'Turkey',
    'Ukraine',
    'United Kingdom',
    'United States',
    'Uruguay',
]


function CountriesList() {
    return (
        <Container maxW={'7xl'} py={12}>
            <Heading as={'h5'} fontSize={'3xl'}>Browse by country</Heading>
            <SimpleGrid columns={[2, 3, 6]} spacing={8} pt={8}>
                {countries.map((country, index) => (
                    <NextLink href={'/country/' + slugify(country)} passHref key={index} legacyBehavior>
                        <Link fontSize={'xl'}>{country}</Link>
                    </NextLink>
                ))}
            </SimpleGrid>
        </Container>
    )
}

export default CountriesList;
