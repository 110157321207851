import { Box, Grid, GridItem, Heading, Link, Tag, Text } from "@chakra-ui/react";
import NextLink from "next/link";

const JobsAsGrid = ({ jobs }) => {
    return (
        <Grid
            templateRows='repeat(1, 1fr)'
            templateColumns={['repeat(1, 1fr)', 'repeat(3, 1fr)', 'repeat(4, 1fr)']}
            gap={[8, 6]}
            pb={[6, 6]}
        >
            {jobs?.map((job, index) => {
                if (job.type !== 'FEATURED') {
                    return (
                        <GridItem key={index} colSpan={[4, 3, 1]}>
                            <NextLink href={`/job/${job.position_slug}/${job.job_id}`} passHref legacyBehavior>
                                <Link>
                                    <Box borderWidth={job.type === 'FEATURED' ? "2px" : "1px"} borderColor={job.type === 'FEATURED' ? "gray.200" : "gray.500"} bgColor={job.type === 'FEATURED' ? "#1B1B1B" : "#1B1B1B"} borderRadius="lg" p={4} minH={'120px'}>
                                        <Heading as={'h4'} fontSize={'xl'}>{job.position}</Heading>
                                        <Text fontSize={'xl'}>{job.company_name}</Text>

                                        {job?.employment_type && <Tag mt={2}>{job.employment_type}</Tag>}
                                        {job?.experience && <Tag ml={2} mt={2}>{job.experience}</Tag>}
                                        {job?.region && <Tag ml={2} mt={2}>{job.region}</Tag>}
                                    </Box>
                                </Link>
                            </NextLink>
                        </GridItem>
                    )
                }
            })}
        </Grid>
    )
}

export default JobsAsGrid;
